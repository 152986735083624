<template>
  <div class="bgStyle">
    <div class="head">销售订单<span>新增</span></div>
    <div class="oper">
      <a-space>
        <a-button type="primary" @click="save">保存</a-button>
        <a-button @click="back">返回</a-button>
      </a-space>
    </div>

    <div class="basic">
      <div class="tit">基本信息<span>（订单编号在保存订单后生成）</span></div>
      <a-form-model ref="basicForm" :model="formData" :rules="rules">
        <div class="info">
          <a-form-model-item label="外部单号" prop="sourceNo">
            <a-input v-model="formData.sourceNo" style="width: 50%"></a-input>
          </a-form-model-item>
          <a-form-model-item label="订单来源" prop="sourceType">
            <a-select
              style="width: 50%"
              ref="select"
              v-model="formData.sourceType"
              placeholder="请选择订单来源"
              allowClear
            >
              <a-select-option
                v-for="item in arrDocTypes"
                :value="item.codeName"
                :key="item.codeName"
                >{{ item.codeName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="下单日期" prop="orderDate">
            <a-date-picker
              v-model="formData.orderDate"
              style="width: 50%"
              valueFormat="YYYY-MM-DD"
            />
          </a-form-model-item>
          <a-form-model-item label="销售人员" prop="salesUserId">
            <a-select
              :value="formData.salesUserId"
              style="width: 50%"
              show-search
              :default-active-first-option="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearchSalesman"
            >
              <a-select-option
                v-for="item in dataSourceSalesman"
                :value="item.userId"
                :key="item.userId"
                @click="handleChangeSalesman(item)"
                >{{ item.fullName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="客户" prop="customerID">
            <a-select
              :value="formData.customerID"
              style="width: 50%"
              show-search
              :default-active-first-option="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearchClient"
            >
              <a-select-option
                v-for="item in dataSourceClient"
                :value="item.id"
                :key="item.id"
                @click="handleClickClient(item)"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
    <div class="goods">
      <div class="tit">商品信息</div>
      <div>
        <a-button class="editable-add-btn" @click="handleAdd"> 新增 </a-button>
        <a-table
          :data-source="dataSource"
          :columns="columns"
          :pagination="false"
        >
          <template slot="del" slot-scope="text, record">
            <a-icon
              v-if="dataSource.length > 1"
              type="minus-circle"
              @click="onDelete(record.key)"
            />
          </template>
          <template slot="name" slot-scope="text, record">
            <a-select
              :value="record.goodName"
              style="width: 100%"
              show-search
              :default-active-first-option="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearch"
            >
              <a-select-option
                v-for="item in dataSourceGoodsName"
                :value="item.id"
                :key="item.id"
                @click="handleClick(item, record)"
                >{{ item.goodsName }}
              </a-select-option>
            </a-select>
          </template>
          <template slot="num" slot-scope="text, record">
            <a-input-number
              v-model="record.orderNum"
              :min="0"
              :precision="0"
              style="width: 100%"
            />
          </template>
          <template slot="price" slot-scope="text, record">
            <a-input-number
              v-model="record.price"
              :min="0"
              style="width: 100%"
            />
          </template>
          <template slot="deliveryTime" slot-scope="text, record">
            <a-date-picker
              v-model="record.deliveryData"
              style="width: 100%"
              format="YYYY-MM-DD"
            />
          </template>
          <template slot="isCustom" slot-scope="text, record">
            <a-select v-model="record.isCustomized" style="width: 60px">
              <a-select-option :value="0" :key="1"> 否 </a-select-option>
              <a-select-option :value="1" :key="2"> 是 </a-select-option>
            </a-select>
          </template>
          <template slot="customAttach" slot-scope="text, record">
            <a-upload
              v-if="record.isCustomized"
              name="file"
              :multiple="true"
              @change="handleChangeUpload"
            >
              <a-button type="primary">
                <a-icon type="upload" /> 上传需求附件
              </a-button>
            </a-upload>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "",
    dataIndex: "del",
    scopedSlots: { customRender: "del" },
  },
  {
    title: "商品名称",
    dataIndex: "goodName",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "购买数量",
    dataIndex: "orderNum",
    scopedSlots: { customRender: "num" },
  },
  {
    title: "单价",
    dataIndex: "price",
    scopedSlots: { customRender: "price" },
  },
  {
    title: "交期",
    dataIndex: "deliveryData",
    scopedSlots: { customRender: "deliveryTime" },
  },
  {
    title: "是否定制",
    dataIndex: "isCustomized",
    scopedSlots: { customRender: "isCustom" },
  },
  {
    title: "定制附件",
    dataIndex: "customAttach",
    scopedSlots: { customRender: "customAttach" },
  },
];

import { getCurrentCompanyUsersList } from "@/api/companyuser";
import { createSalesOrder } from "@/api/salesOrder";
import { getListByCodeKeyPath } from "@/api/code.js";
import { getListCustomer } from "/src/api/customer.js";
import { getList } from "/src/api/purchase.js";
export default {
  name: "SalesManageNew",
  components: {},
  data() {
    return {
      formData: {
        externalOrder: "",
        orderFrom: "",
        orderDate: undefined,
        salesman: "",
        salesUserId: "",
        customerID: "",
      },
      arrDocTypes: [],
      count: 2,
      dataSource: [],
      columns,
      dataSourceGoodsName: [],
      dataSourceSalesman: [],
      dataSourceClient: [],

      rules: {
        sourceNo: [{ required: true, message: "必填项", trigger: "blur" }],
        sourceType: [{ required: true, message: "必填项", trigger: "change" }],
        orderDate: [{ required: true, message: "必填项", trigger: "change" }],
        salesUserId: [{ required: true, message: "必填项", trigger: "blur" }],
        customerID: [{ required: true, message: "必填项", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getCurrentCompanyUsersList();
    var _this = this;
    getListByCodeKeyPath("HS_SourceType")
      .then((res) => {
        _this.arrDocTypes = res;
      })
      .catch(() => {
        _this.$message.error("获取来源订单类型失败，请重试～！");
      });
    this.getGoodsList();
    this.getListCustomer();
  },
  methods: {
    getGoodsList(params) {
      if (!params) params = {};
      params.shelfStatus=1;
      getList(params).then((res) => {
        this.dataSourceGoodsName = res.items;
      });
    },
    getListCustomer(param) {
      if (!param) param = {};
      getListCustomer(param).then((res) => {
        this.dataSourceClient = res.items;
      });
    },
    /**
     * 查询销售人员
     * @param (object) param 查询条件
     */
    getCurrentCompanyUsersList(param) {
      if (!param) param = {};
      param.isFilterInvitationStatus = true;
      param.maxResultCount = 10;
      param.skipCount = 0;
      getCurrentCompanyUsersList(param).then((res) => {
        this.dataSourceSalesman = res.items;
      });
    },

    save() {
      this.$refs["basicForm"].validate((valid) => {
        if (valid) {
          this.formData.salesOrderDetails = [];
          this.dataSource.forEach((item) => {
            var detail = {
              goodID: item.goodId,
              goodName: item.goodName,
              orderNum: item.orderNum,
              price: item.price,
              deliveryData: item.deliveryData,
              isCustomized: item.isCustomized == 0 ? false : true,
            };
            this.formData.salesOrderDetails.push(detail);
          });
          createSalesOrder(this.formData).then((res) => {
            this.$message.success("创建销售订单成功");
            this.back();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    handleSearchSalesman(searchText) {
      var param = {
        filter: searchText,
      };
      this.getCurrentCompanyUsersList(param);
    },
    handleChangeSalesman(e) {
      const value = e;
      this.formData.salesUserId = value.userId;
      this.formData.salesName = value.fullName;
    },
    handleSearchClient(value) {},
    handleClickClient(e) {
      const value = e;
      this.formData.customerID = value.id;
    },
    handleSearch(value) {
      var param = {
        filter: value,
      };
      this.getGoodsList(param);
    },
    handleClick(entiy, record) {
      record.goodId = entiy.id;
      record.goodName = entiy.goodsName;
    },
    handleAdd() {
      const { count, dataSource } = this;
      const newData = {
        key: count,
        goodName: "",
        orderNum: 0,
        price: 0,
        deliveryData: null,
        isCustomized: 0,
        customAttach: 0,
      };
      this.dataSource = [...dataSource, newData];
      this.count = count + 1;
    },
    onDelete(key) {
      const dataSource = [...this.dataSource];
      this.dataSource = dataSource.filter((item) => item.key !== key);
    },
    handleChangeUpload() {
      console.log("handlechange");
    },
  },
};
</script>

<style lang="scss" scoped>
.oper {
  text-align: right;
}

.head {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding-bottom: 6px;
  margin-bottom: 16px;
}

.tit {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
  span {
    font-size: 12px;
  }
}

.basic {
  margin-bottom: 26px;
  .info {
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 30%;
      margin-right: 16px;
    }
  }
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
