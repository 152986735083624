import {
    postRequest,
    updateRequest,
    getRequest,
} from "@/utils/http";

/** 查询销售订单集合 */
export const getSalesOrderList = (parm) => getRequest(`/common/SalesOrder/getList`, parm);
/**查询单条销售订单 */
export const getSalesOrder = (id) => getRequest(`/common/SalesOrder/${id}`);
/** 新建销售订单 */
export const createSalesOrder = (parm) => postRequest(`/common/SalesOrder/create`, parm);
/** 修改销售订单 */
export const updateSalesOrder = (id, parm) => updateRequest(`/common/SalesOrder/update/${id}`, parm);
/** 修改销售订单 */
export const updateStateSalesOrder = (id, parm) => updateRequest(`/common/SalesOrder/updateState/${id}`, parm);

/** 导入创建销售订单 */
export const CreateImportAsync = (parm) => postRequest(`/common/SalesOrder/createImport`, parm);

/** 导入销售订单 */
export const ImportSalesOrderAsync = (parm) => postRequest(`/common/SalesOrder/importSalesOrder`, parm);
